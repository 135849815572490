<template>
  <div>
    <b-row align-h="center" v-if="dataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-card v-else>
      <b-row>
        <b-col md="12">
          <h4 class="mb-1">Select Evaluation Sheet</h4>
          <b-button
            v-for="dep in items"
            :key="dep.id"
            class="mr-1"
            :variant="current == dep.id ? 'primary' : 'outline-primary'"
            @click="sheetSelect(dep)"
          >
            {{ dep.title }}
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center" v-if="loading" class="mt-2">
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
      <b-row class="mt-2" v-else>
        <b-col md="12" class="mb-1" v-if="currentPoints.length > 0">
          <h4>Choose</h4>
        </b-col>
        <b-col
          md="12"
          class="d-flex align-items-center mb-1"
          v-for="(item, ind) in currentPoints"
          :key="ind"
        >
          <b-form-checkbox
            :id="item.id"
            v-model="pointIDs"
            :value="item.id"
            @input="checkSelect()"
          >
            <h4 class="ml-1">{{ item.details }}</h4>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row align-h="end" class="mt-1">
        <b-col md="2">
          <b-button variant="success" block @click="save()" :disabled="request">
            <b-spinner v-if="request" small type="grow" />
            <span v-else> Save </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BInputGroup,
    // vSelect,
    // flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  async created() {
    // console.log(this.$route.params.id);
    //   if (this.$route.params.id != 0) this.LoadData();
    this.myObj.staffID = this.$route.params.id;
    console.log(this.myObj);
    this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.details.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Expense",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      depLoading: false,
      loading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "voucher", key: "voucherNumStr" },
        { label: "account", key: "account" },
        { label: "date", key: "date" },
        // { label: "details", key: "details" },
        { key: "actions", label: "actions" },
      ],
      items: [
        // {
        //   id: 9,
        //   account: 90,
        //   date: "2023-01-10",
        //   details: "new",
        //   campusID: this.$store.state.userData.cId,
        // },
      ],
      deptOptions: [],
      selected: null,
      rangeDate: null,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      myObj: {
        id: 0,
        staffID: 0,
        performanceID: 0,
      },
      pointIDs: [],
      currentPoints: [],
      pointObj: {
        id: 0,
        details: "",
        performanceCateoryID: 0,
        campusID: this.$store.state.userData.cId,
      },
      current: 0,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    AddPoint() {
      this.myObj.points.push({
        id: 0,
        details: "",
        performanceCateoryID: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.myObj);
    },
    async remove(item, ind) {
      if (item.id == 0) {
        this.myObj.points.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        // console.log("api");
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffPerformanceSheet/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj.points.splice(ind, 1);
        // console.log(this.myObj);
      }
    },
    checkSelect() {
      console.log(this.pointIDs);
    },
    async sheetSelect(item) {
      this.current = item.id;
      this.myObj.performanceID = item.id;
      this.loading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffPerformanceSheet/LoadData?db=" +
          this.$store.state.userData.db +
          "&id=" +
          item.id,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      this.loading = false;
      this.pointIDs = [];
      this.currentPoints = this.myObj.points;
    },
    async save() {
      // this.request = true;
      // var status = await this.post({
      //   url:
      //     this.$store.state.domain +
      //     " /Save?db=" +
      //     this.$store.state.userData.db,
      //   body: this.myObj,
      //   message: "Performance sheet added successfully.",
      //   context: this,
      //   token: this.$store.state.userData.token,
      // });
      // this.request = false;
      // if (status) {
      //   this.$router.go(-1);
      // }
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffPerformanceCategories?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      // console.log("obj", this.myObj);
      this.current = this.myObj.performanceID;
      this.dataLoading = false;
    },
    async LoadDep() {
      this.depLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.deptOptions = await this.get(obj);
      // console.log("dept", this.deptOptions);
      this.depLoading = false;
    },
  },
};
</script>
